import request from '@/utils/request'


// 查询兑换码列表
export function listKoc(query) {
  return request({
    url: '/user/kocs/list',
    method: 'get',
    params: query
  })
}

// 查询兑换码分页
export function pageKoc(query) {
  return request({
    url: '/user/kocs/page',
    method: 'get',
    params: query
  })
}

// 查询兑换码详细
export function getKoc(data) {
  return request({
    url: '/user/kocs/detail',
    method: 'get',
    params: data
  })
}

// 新增兑换码
export function addKoc(data) {
  return request({
    url: '/user/kocs/add',
    method: 'post',
    data: data
  })
}

// 修改兑换码
export function updateKoc(data) {
  return request({
    url: '/user/kocs/edit',
    method: 'post',
    data: data
  })
}

// 删除兑换码
export function delKoc(data) {
  return request({
    url: '/user/kocs/delete',
    method: 'post',
    data: data
  })
}
